import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getDropInfo, getFiatInformation } from '@api/api';
import {
    PACK_DROPS_LIST,
    PACKS_TEMPLATE_LIST,
    WAX_SIGN_IN,
    WAX_PAYMENT,
    FIAT_PAYMENT_ENABLED,
    WAX,
    FIAT
} from '@utils/globals';
import IsReleased from '@components/IsReleased';
import ErrorModal from '@components/ErrorModal';
import Ribbon from '@components/Ribbon';
import Pack from '@context/Shop/Pack';
import Loading from '@components/Loading';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import { withUAL } from 'ual-reactjs-renderer';
import HowToBuy from '@components/HowToBuy';
import { useTheme } from '@emotion/react';
import Container from '@components/Container';

import atomicHubLogo from '@images/atomichub.png';
import waxStashLogo from '@images/waxStash.png';
import nftHiveLogo from '@images/nftHive.svg';

const Logo = styled.img(({ theme }) => ({
    width: '40vw',
    marginBottom: theme.spacing.l,
    objectFit: 'contain',
    userSelect: 'none',
    [theme.mediaQuery.tabletLandscapeUp]: {
        width: '30vw'
    }
}));

const SecondaryMarketContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `${theme.spacing.xl} auto`,
    padding: theme.spacing.xs,
    width: 'max-content',
    color: theme.colors.primary.contrastText,
    backgroundColor: theme.colors.primary.main,
    borderRadius: theme.borderRadius.m
}));

const SecondaryMarketLogosContainer = styled.div(({ theme }) => ({
    width: theme.size.secondaryMarketContainerWidth,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}));

const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: 'auto',
    maxWidth: theme.size.maxContentWidth
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    padding: theme.spacing.xs,
    width: theme.size.secondaryMarketLogosWidth,
    objectFit: 'contain'
}));

const Headline = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    ...theme.typography.headers,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    color: theme.colors.primary.dark,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const PackDetailsTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    ...theme.typography.headers,
    textTransform: 'uppercase',
    color: theme.colors.common.text,
    fontWeight: 'bold',
    letterSpacing: '4px',
    marginBottom: theme.spacing.xxs
}));

function BuyPacks({ ual }) {
    const isReleased = IsReleased();
    const theme = useTheme();

    const [ packsInfo, setPacksInfo ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState("");

    useEffect(() =>  {
        window.scrollTo(0, 0);

        const updatePackInfo = (templateId, info, key, index) => {
            if (aux[index]) {
                aux[index].info.set(key, info);
            } else {
                templateId = templateId.toString();
                let map = new Map();
                aux[index] = {
                    templateId: templateId,
                    info: map.set(key, info)
                };
            }

            if (key === WAX) {
                countWax += 1;
            } else {
                countFiat += 1;
            }

            if (checkGotAllInfo()) { setPacksInfo(aux); }
        };

        const checkGotAllInfo = () => {
            let gotWAXInfo = WAX_PAYMENT ? countWax === PACK_DROPS_LIST.length : true;
            let gotFiatInfo = FIAT_PAYMENT_ENABLED ? countFiat === PACKS_TEMPLATE_LIST.length : true;
            return gotWAXInfo && gotFiatInfo;
        };

        let countFiat = 0, countWax = 0;
        let isMounted = true;
        let aux = [];
        if (isMounted && WAX_PAYMENT) {
            PACK_DROPS_LIST.forEach((drop_id, index) => {
                getDropInfo(
                    parseInt(drop_id),
                    (info) => { updatePackInfo(info.template_id, info, WAX, index); },
                    (errorMessage) => setErrorMessage(errorMessage)
                );
            });
        }

        if (isMounted && FIAT_PAYMENT_ENABLED) {
            PACKS_TEMPLATE_LIST.forEach((template_id, index) => {
                getFiatInformation(
                    parseInt(template_id),
                    (info) => {
                        updatePackInfo(template_id, info, FIAT, index);
                    },
                    (errorMessage) => setErrorMessage(errorMessage)
                );
            });
        }
        return () => { isMounted = false; };
    }, []);

    const isAnySoldOut = () => {
        packsInfo.forEach((value) => {
            return value.info.forEach((value) => {
                if (value.available === 0) {
                    return true;
                }
            });
        });
    };

    const renderSecondaryMarketLinks = () => {
        return isAnySoldOut() ? (
            <SecondaryMarketContainer>
                <h2>{STRINGS.secondaryMarketTitle}</h2>
                <SecondaryMarketLogosContainer>
                    <a href={RES.secondaryMarketLinks.nftHive.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={nftHiveLogo} alt={RES.secondaryMarketLinks.nftHive.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.atomicHub.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHub.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.waxStash.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={waxStashLogo} alt={RES.secondaryMarketLinks.waxStash.label} />
                    </a>
                </SecondaryMarketLogosContainer>
            </SecondaryMarketContainer>
        ): null;
    };

    const renderPacks = () => {
        let packsToRender = [];
        packsInfo.forEach((value, index) => {
            packsToRender.push(<Pack packInfo={value.info} templateId={value.templateId} key={index} />);
        });
        return packsToRender;
    };

    return (
        packsInfo ?
            <>
                {
                    isReleased ? "" : <Ribbon />
                }
                <Wrapper>
                    { renderPacks() }
                </Wrapper>
                { WAX_SIGN_IN ? (
                    <Container
                        direction="column"
                        padding={theme.spacing.l}
                        gap={theme.spacing.m}
                        style={{ backgroundColor: theme.colors.secondary,
                            color: theme.colors.primary.dark,
                            marginTop: theme.spacing.l
                        }}
                    >
                        <Headline>{STRINGS.howToBuyHeadline}</Headline>
                        <HowToBuy />
                    </Container>
                ) : null }
                {isReleased ? renderSecondaryMarketLinks() : null}
                <ErrorModal show={errorMessage !== ""} onClose={() => setErrorMessage("")} >
                    {errorMessage}
                </ErrorModal>
            </>
            :
            <Loading />
    );
}

export default WAX_SIGN_IN ? withUAL(BuyPacks) : BuyPacks;
