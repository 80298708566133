import * as GLOBAL_VARS from '@utils/globals';
import { v4 as uuidGenerator } from 'uuid';
import { Cookies } from 'react-cookie';
import theme from '@styles/theme';

const cookies = new Cookies();

export const COOKIE_NAME = GLOBAL_VARS.COLLECTION_NAME + "_BROWSER_CONTEXT";

export const FIAT_TRANSACTION_STATES = {
    new: { key: "new", label: "New", color: theme.colors.common.text, backgroundColor: theme.colors.common.background },
    reserved: { key: "reserved", label: "Reserved", color: theme.colors.common.text, backgroundColor: theme.colors.common.background },
    outofstock: { key: "outofstock", label: "Out of Stock", color: theme.colors.error.light, backgroundColor: theme.colors.common.background },
    pay_pending: { key: "pay_pending", label: "Payment Pending", color: theme.colors.warning.light, backgroundColor: theme.colors.common.background },
    pay_authorized: { key: "pay_authorized", label: "Payment Authorized", color: theme.colors.common.text, backgroundColor: theme.colors.common.background },
    pay_error: { key: "pay_error", label: "Payment Error", color: theme.colors.error.light, backgroundColor: theme.colors.common.background },
    transfering: { key: "transfering", label: "Transferring", color: theme.colors.common.text, backgroundColor: theme.colors.common.background },
    transfer_retry_1: { key: "transfer_retry_1", label: "Transfer - First Retry", color: theme.colors.warning.light, backgroundColor: theme.colors.common.background },
    transfer_retry_2: { key: "transfer_retry_2", label: "Transfer - Second Retry", color: theme.colors.warning.light, backgroundColor: theme.colors.common.background },
    transfer_error: { key: "transfer_error", label: "Transfer Error", color: theme.colors.error.light, backgroundColor: theme.colors.common.background },
    transfer_complete: { key: "transfer_complete", label: "Transfer Complete", color: theme.colors.success.light, backgroundColor: theme.colors.common.background },
    pay_complete: { key: "pay_complete", label: "Payment Complete", color: theme.colors.success.light, backgroundColor: theme.colors.common.background },
    unknown_error: { key: "unknown_error", label: "Unknown Error Occurred", color: theme.colors.error.light, backgroundColor: theme.colors.common.background }
};

export const getBrowserContext = () => {
    let uuid = cookies.get(COOKIE_NAME);
    if (!uuid) {
        uuid = uuidGenerator();
        cookies.set(COOKIE_NAME, uuid);
    }
    return uuid;
};
