import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';

const CustomLink = styled.a(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h6 : theme.typography.p;
    let headerStyle = header ? {
        marginLeft: 0,
        marginBottom: theme.spacing.s,
        [theme.mediaQuery.tabletLandscapeUp]: {
            marginBottom: 0
        }
    } : {};
    let padding = tab ? {
        padding: `${theme.spacing.xs} ${theme.spacing.s}`
    } : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.primary.dark : theme.colors.primary.dark,
        borderBottom: isActive() ? `solid ${theme.colors.primary.dark} 3px` : 'none',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        opacity: 1,
        '&:hover': {
            color: theme.colors.primary.light,
            cursor: 'pointer'
        },
        ...padding,
        ...headerStyle
    };
});

function Link({ href, children, header, tab, activeWhen, onClick, match, history, location, style }) {
    function isActive() {
        if (href) {
            return location.pathname.includes(href.slice(2));
        }
    }

    return (
        <CustomLink href={href} header={header} tab={tab} isActive={isActive} onClick={onClick}>{children}</CustomLink>
    );
}

export default withRouter(Link);
