/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import StyledFormModal from '@components/FormModal';
import STRINGS from '@utils/strings';
import { isReleased, SHOW_HEADER, SHOW_BUY_PAGE, WAX_SIGN_IN, IPFS_ENDPOINT } from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import Container from '@components/Container';
import HowToBuy from '@components/HowToBuy';
import Hero from '@components/Hero';
import Spotlight from '@components/Spotlight';
import Button from '@components/Button';
import Mosaic from '@components/Mosaic';

import waxCo2Logo from '@images/wax-co2-neutral-logo.png';
import cotrLogo from '@images/COTR_Logo.webp';

const InfoContainer = styled.div(({ theme }) => ({
    minHeight: SHOW_HEADER ? `calc(100vh - ${theme.size.headerHeight})` : '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto'
}));

const Headline = styled.h2(({ theme }) => ({
    ...theme.typography.h1,
    ...theme.typography.headers,
    textAlign: 'center',
    margin: `${theme.spacing.xs} ${theme.spacing.xxs} `,
    color: theme.colors.common.text,
    [ theme.mediaQuery.desktopUp ]: {
        margin: ` 0 ${theme.spacing.xs} 0`
    }
}));

const UpcomingHeadline = styled.h1(({ theme }) => ({
    ...theme.typography.headers,
    // textTransform: 'uppercase',
    color: theme.colors.common.text,
    lineHeight: '130%',
    textAlign: 'left',
    maxWidth: '60ch',
    letterSpacing: 2.8,
    [theme.mediaQuery.largeMobileUp]: {
        textAlign: 'center'
    }
}));

const UpcomingSubtext = styled.p(({ theme }) => ({
    color: theme.colors.common.text,
    lineHeight: '180%',
    textAlign: 'left !important',
    maxWidth: '85ch',
    fontSize: '1.125rem',
    fontWeight: 300,
    [theme.mediaQuery.largeMobileUp]: {
        textAlign: 'center'
    }
}));

const BlogPost = styled.a(({ theme }) => ({
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.primary.main,
    padding: `${theme.spacing.xxxs}`,
    borderBottom: `1px solid ${theme.colors.primary.dark}`,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: 3,
    opacity: 1,
    margin: `${theme.spacing.m}`,
    '&:hover': {
        color: theme.colors.primary.light
    }
}));

const LogoImage = styled.img(() => ({
    width: '100%',
    maxWidth: '340px'
}));

const LogoSubtitle = styled.p(({ theme }) => ({
    color: theme.colors.common.text,
    lineHeight: '180%',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: 300
}));

const Image = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    margin: `${theme.spacing.xl} 0 ${theme.spacing.xs}`,
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: 'bold'
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.common.text,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.text,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        width: 395
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary.main}69`
}));

function Home() {
    const theme = useTheme();
    const history = useHistory();

    const [ showHelpModal, setShowHelpModal ] = useState(false);
    const [ showFormModal, setShowFormModal ] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <InfoContainer>
                <Hero
                    mainImage={RES.images.hero}
                    secondaryImage={false}
                    title={STRINGS.homePage.headline}
                    subtitle={STRINGS.homePage.subtext}
                    portrait={RES.images.portrait}
                    linkUrl={RES.externalLinks.store}
                    button
                    buttonCallback={isReleased() ? () => history.push(ROUTES.buypacks) : () => setShowFormModal(true)}
                />
                <Container
                    direction="column"
                >
                    <Container
                        direction="column"
                        gap={theme.spacing.s}
                        padding={theme.spacing.m}
                    >
                        <UpcomingHeadline>{STRINGS.homePage.collectionTitle}</UpcomingHeadline>
                        <UpcomingSubtext>{STRINGS.homePage.collectionDescription}</UpcomingSubtext>
                    </Container>
                    <Mosaic items={RES.samples} />
                    <Container  direction="row" gap={theme.spacing.xxl} padding={theme.spacing.l}>
                        { RES.packs.map((pack, index) => (
                            <Container key={index} direction="column" gap={theme.spacing.l} style={{ maxWidth: '500px' }}>
                                <Image src={`${IPFS_ENDPOINT}${pack.srcImg}`} alt={pack.caption} />
                                <Container direction="column">
                                    <PackDescription style={{ fontSize: '22px' }}>{pack.description}</PackDescription>
                                    <PackDescription style={{ textTransform: 'uppercase' }}>{pack.quantity}</PackDescription>
                                </Container>
                                <Container direction="column" alignItems="center">
                                    <PackDetailsTitle>Pack Probabilities</PackDetailsTitle>
                                    <PackDetailsList>
                                        {pack.probabilities &&
                                            pack.probabilities.map((line, index) => (
                                                <PackDetailItem key={index}>
                                                    <span>
                                                        {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                    </span>
                                                    <span>
                                                        {line.probability}
                                                    </span>
                                                </PackDetailItem>
                                            ))
                                        }
                                    </PackDetailsList>
                                </Container>
                            </Container>
                        )) }
                    </Container>
                    <Container
                        direction="column"
                        padding={theme.spacing.m}
                        style={{ marginBottom: theme.spacing.xs }}
                    >
                        <UpcomingSubtext>{STRINGS.homePage.collectionSecondDescription}</UpcomingSubtext>
                    </Container>
                    <Spotlight
                        image={waxCo2Logo}
                        title={STRINGS.homePage.spotlightTitle}
                        description={STRINGS.homePage.spotlightDescription}
                    />
                    <Spotlight
                        image={cotrLogo}
                        title={STRINGS.homePage.donationTitle}
                        description={STRINGS.homePage.donationDescription}
                        invert
                    />
                    <Container
                        direction="column"
                        gap={theme.spacing.xs}
                        padding={theme.spacing.xs}
                    >
                        <LogoImage src={RES.images.horizontalLogo} alt={STRINGS.campaign} />
                        <LogoSubtitle>{STRINGS.homePage.logoSubtitle}</LogoSubtitle>
                    </Container>
                    <BlogPost href={RES.externalLinks.blog.link} target="_blank" rel="noopener noreferrer">{RES.externalLinks.blog.label}</BlogPost>
                </Container>
                <Container padding={theme.spacing.s}>
                    <Button
                        secondary
                        onClick={() => setShowHelpModal(true)}
                    >
                        {STRINGS.help}
                    </Button>
                </Container>
                { !SHOW_BUY_PAGE && WAX_SIGN_IN ? (
                    <Container
                        direction="column"
                        padding={theme.spacing.l}
                        gap={theme.spacing.m}
                        style={{ backgroundColor: theme.colors.secondary,
                            color: theme.colors.primary.dark,
                            marginTop: theme.spacing.l
                        }}
                    >
                        <Headline>{STRINGS.howToBuyHeadline}</Headline>
                        <HowToBuy />
                    </Container>
                ) : null }
            </InfoContainer>
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
                portalId='19612576'
                formId='b9a9035c-1a28-4253-918c-22e9e0680795'
            />
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </>
    );
}

export default Home;
