import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import RES from '@utils/resources';
import StyledButton from '@components/Button';
import { isReleased } from '@utils/globals';

const ContentContainer =  styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.primary.dark,
    textAlign: 'left',
    gap: theme.spacing.xxs,
    [ theme.mediaQuery.largeMobileOnly ]: {
        alignItems: 'center'
    },
    'a': {
        color: theme.colors.primary.dark,
        textDecoration: 'underline',
        '&:hover': {
            color: theme.colors.primary.light,
            cursor: 'pointer'
        }
    }
}));

const Title =  styled.h3(({ theme }) => ({
    ...theme.typography.headers,
    fontSize: '1.75rem'
}));

const Subtitle =  styled.p(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600
}));

const Description =  styled.p(({ theme }) => ({
    ...theme.typography.p,
    lineHeight: '180%',
    fontWeight: 300,
    margin: '1rem 1rem 1rem 0'
}));

const Image = styled.img(({ theme }) => ({
    width: 200,
    objectFit: 'contain',
    margin: '1rem'
    // boxShadow: '-1rem 1rem 0px #265CB2',
    // [ theme.mediaQuery.largeMobileUp ]: {
    //     width: 260,
    //     margin: '2rem',
    //     boxShadow: '-2rem 2rem 0px #265CB2'
    // }
}));

const ButtonStyle = {
    width: 'fit-content',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
    '&:disabled': {
        opacity: '75%',
        cursor: 'not-allowed'
    }
};

const SpotlightStyle =  styled.div(({ theme, invert }) => ({
    display: 'flex',
    flexDirection: invert ? 'row-reverse' : 'row',
    color: theme.colors.primary,
    maxWidth: '58rem',
    gap: theme.spacing.xl,
    alignItems: 'center',
    padding: theme.spacing.xxs,
    marginBottom: theme.spacing.l,
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column',
        padding: '0 16px',
        gap: theme.spacing.m
    }
}));

function Spotlight({ invert, image, title, subtitle, description, button, buttonCallback, buttonDisabled=false }) {
    return (
        <SpotlightStyle invert={invert}>
            { image ? <Image src={image} alt="image" /> : null }
            <ContentContainer>
                { title ? <Title>{title}</Title> : null }
                { subtitle ? <Subtitle>{subtitle}</Subtitle> : null }
                { description ? <Description>{description}</Description> : null }
                { button ? <StyledButton disabled={buttonDisabled} style={ButtonStyle} onClick={buttonCallback}>{button}</StyledButton> : null }
            </ContentContainer>
        </SpotlightStyle>
    );
}

export default Spotlight;