import styled from '@emotion/styled';
import { useState } from 'react';
import Button from '@components/Button';
import StyledFormModal from '@components/FormModal';
import STRINGS from '@utils/strings';

const ItemContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.s,
    transition: theme.transition.default
}));

const Question = styled.h3(({ theme, isOpen }) => ({
    ...theme.typography.h3,
    ...theme.typography.headers,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xs,
    transition: theme.transition.default,
    '&:hover': {
        color: theme.colors.primary.light,
        cursor: 'pointer'
    }
}));

const Answer = styled.div(({ theme, isOpen }) => ({
    ...theme.typography.p,
    marginBottom: theme.spacing.xs,
    overflow: 'hidden',
    height: isOpen ? 'auto' : '0',
    transition: theme.transition.default,
    color: theme.colors.common.text,
    'a': {
        color: theme.colors.primary.dark,
        '&:hover': {
            color: theme.colors.primary.light
        }
    },
    'p': {
        marginBottom: theme.spacing.xs
    }
}));

function FAQItem({ question, answer, action }) {
    const [ isOpen, setIsOpen ] = useState(false);

    const [ showModal, setShowModal ] = useState(false);

    return (
        <ItemContainer>
            <Question isOpen={isOpen} onClick={() => setIsOpen(prevState => !prevState)}>{question}</Question>
            <Answer isOpen={isOpen}>
                {answer}
                { action ? <Button onClick={() => setShowModal(true)}>{STRINGS.form}</Button> : null }
            </Answer>
            { action ? (
                <StyledFormModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    title={STRINGS.redemptionForm}
                    portalId='19612576'
                    formId='f36d99b8-92c9-4e34-8b4f-8087952aece1'
                />
            ) : null }
        </ItemContainer>
    );
}

export default FAQItem;
