import styled from '@emotion/styled';

const Button = styled.button(({ secondary, theme, style }) => ({
    ...theme.typography.pTiny,
    color: secondary ? theme.colors.primary.dark : theme.colors.primary.main,
    backgroundColor: secondary ? theme.colors.primary.main : theme.colors.primary.dark,
    padding: `${theme.spacing.xxs} ${theme.spacing.s}`,
    border: secondary ? `2px solid ${theme.colors.primary.dark}` : 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: 3,
    opacity: 1,
    transition: theme.transition.default,
    boxShadow: theme.shadow.dropShadow,
    '&:hover': {
        color: secondary ? theme.colors.primary.main : theme.colors.primary.dark,
        backgroundColor: secondary ? theme.colors.primary.dark : theme.colors.primary.main,
        cursor: 'pointer'
    },
    '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed'
    },
    ...style
}));

function StyledButton({ children, primary, secondary, text, style={}, ...otherProps }) {
    return <Button secondary={secondary} style={style} {...otherProps}>{children}</Button>;
}

export default StyledButton;
