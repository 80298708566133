import RES from '@utils/resources';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

if (IS_PRODUCTION) {
    setInfo(336265, {
        imgHash: 'QmdAVRB9yWrPh7RMwM2c3jPRTLgD3Kyu9SMVEdX4wmTFq1',
        name: 'Big Pack',
        isImg: true,
        variant: ''
    });
    setInfo(336267, {
        imgHash: 'QmXoyQPqD5qUrmfUPybyGHXPa5dWVXTdgZgD53ZizHzqmu',
        name: 'Huge Pack',
        isImg: true,
        variant: ''
    });
} else {
    setInfo(256621, {
        imgHash: 'QmdAVRB9yWrPh7RMwM2c3jPRTLgD3Kyu9SMVEdX4wmTFq1',
        name: 'Big Pack',
        isImg: true,
        variant: ''
    });
    setInfo(256620, {
        imgHash: 'QmXoyQPqD5qUrmfUPybyGHXPa5dWVXTdgZgD53ZizHzqmu',
        name: 'Huge Pack',
        isImg: true,
        variant: ''
    });
}
