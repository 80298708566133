import { COLLECTION_NAME, IPFS_ENDPOINT } from "@utils/globals";
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
import HeroSrc from '@images/hero.jpg';
import Portrait from '@images/portrait.jpg';

import Base from '@images/youth_game.jpg';
import BlackAndWhite from '@images/throwback_gratitude.jpg';
import Signatures from '@images/youth_edge.mp4';
import Blessings from '@images/blessings_destiny.jpg';
import Hok from '@images/YNGDNA_x_HoK_Card.mp4';
import Illustration from '@images/konda_illustration_3.jpg';
import DNA from '@images/dna_venice.jpg';
import YngMetaAvatar from '@images/YNGDNA_x_HoK_Card_zaire-wade.2.mp4';

import Logo from '@images/logo.png';

export const RES = {
    images: {
        logo: 'https://images.squarespace-cdn.com/content/v1/5d77a40ac296755dcf2f0f6c/1569713902574-JREMXUCK322K3CT1GBEJ/s%26ublknw.png?format=750w',
        horizontalLogo: Logo,
        icon: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/logo1.jpeg',

        pack: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/Tommy%20Hearns%20Image%204.png',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        hero: HeroSrc,
        portrait: Portrait,
        video: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/SU%20-%20For%20Website.mp4'
    },

    openingAnimations: [
        {
            template_id: IS_PRODUCTION ? '336265' :'256621',
            openingAnimation: 'https://facings.fra1.cdn.digitaloceanspaces.com/yngdna/bigpacktemplate002_FOIL.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '336267' :'256620',
            openingAnimation: 'https://facings.fra1.cdn.digitaloceanspaces.com/yngdna/hugepacktemplate001_FOIL.mp4'
        }
    ],

    samples: [
        {
            srcImg: Base,
            caption: 'Youth'
        },
        {
            srcImg: BlackAndWhite,
            caption: 'Throwback'
        },
        {
            srcImg: Signatures,
            caption: 'Signature Series',
            type: 'video'
        },
        {
            srcImg: Blessings,
            caption: 'Blessings'
        },
        {
            srcImg: DNA,
            caption: 'DNA'
        },
        {
            srcImg: Hok,
            caption: 'YNG Meta',
            type: 'video'
        },
        {
            srcImg: Illustration,
            caption: 'Illustration'
        },
        {
            srcImg: YngMetaAvatar,
            caption: 'YNG Meta Avatar',
            type: 'video'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: `https://wax.atomichub.io/market?collection_name=${COLLECTION_NAME}`
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: `https://wax.atomichub.io/profile/{0}?collection_name=${COLLECTION_NAME}`
        },
        atomicHubEntireCollection: {
            label: "AtomicHub The Strange and Unusual entire Collection",
            link: `https://wax.atomichub.io/tools/book/${COLLECTION_NAME}/{0}`
        },
        waxStash: {
            label: "WAXStash",
            link: `https://www.waxstash.com/markets?collection_name=${COLLECTION_NAME}`
        },
        nftHive: {
            label: "NFTHIVE",
            link: "https://nfthive.io/collection/yng.dna"
        }
    },

    externalLinks: {
        wcw: {
            label: "Wax Cloud Wallet",
            link: "https://all-access.wax.io"
        },
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/uwBGtanH5T"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        instagram: {
            label: "YNG DNA Instagram Account",
            link: "https://www.instagram.com/yngdna/"
        },
        blog: {
            label: "Learn more about the collection",
            link: "https://wax.atomichub.io/explorer/collection/yng.dna"
        },
        store: {
            label: "YNG DNA Website",
            link: "https://yng-dna.com/"
        },
        hok: {
            label: "House of Kibaa",
            link: "https://houseofkibaa.com/"
        },
        electricToken: {
            label: "Electric Token"
        },
        twitterYngDna: {
            label: "YNG DNA Twitter",
            link: "https://twitter.com/zairewade21"
        },
        facebookYngDna: {
            label: "YNG DNA Facebook",
            link: "https://www.facebook.com/LIFESTYLEYNGDNA/"
        }
    },

    packs: [
        {
            srcImg: 'QmdAVRB9yWrPh7RMwM2c3jPRTLgD3Kyu9SMVEdX4wmTFq1',
            caption: 'Big Pack',
            quantity: '47 Sold',
            description: 'Big Pack of 10 NFTs sold for $20',
            probabilities:
            [
                { rarity: 'Youth', probability: '38%' },
                { rarity: 'Signature Series', probability: '38%' },
                { rarity: 'Throwback', probability: '8%' },
                { rarity: 'Blessings', probability: '8%' },
                { rarity: 'DNA', probability: '8%' },
                { rarity: 'YNG Meta', note: 'Additional chance / Roll', probability: '5.5%' },
                { rarity: 'Illustrations', note: 'Additional chance / Roll', probability: '2.63%' },
                { rarity: 'YNG Meta Avatar', note: 'Additional chance / Roll', probability: '0%' }
            ]
        },
        {
            srcImg: 'QmXoyQPqD5qUrmfUPybyGHXPa5dWVXTdgZgD53ZizHzqmu',
            caption: "Huge Pack",
            quantity: '254 Sold',
            description: 'Huge Pack of 20 NFTs sold for $40',
            probabilities:
            [
                { rarity: 'Youth', probability: '38%' },
                { rarity: 'Signature Series', probability: '38%' },
                { rarity: 'Throwback', probability: '8%' },
                { rarity: 'Blessings', probability: '8%' },
                { rarity: 'DNA', probability: '8%' },
                { rarity: 'YNG Meta', note: 'Additional chance / Roll', probability: '11%' },
                { rarity: 'Illustrations', note: 'Additional chance / Roll', probability: '6.0%' },
                { rarity: 'YNG Meta Avatar', note: 'Additional chance / Roll', probability: '0.10%' }
            ]
        }
    ]
};

export default RES;
