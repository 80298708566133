import styled from '@emotion/styled';
import Container from '@components/Container';

const Image = styled.img(() => ({
    width: '100%',
    objectFit: 'contain'
}));

const Video = styled.video(() => ({
    width: '100%'
}));

const Caption = styled.p(({ theme }) => ({
    color: theme.colors.common.text,
    fontWeight: 'bold',
    marginTop: theme.spacing.xxxs,
    marginBottom: theme.spacing.xxxs
}));

const MosaicContainer = styled.div(({ theme }) => ({
    width: '100%',
    height: 'auto',
    display: 'grid',
    gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: theme.spacing.xs,
    padding: theme.spacing.xs,

    'div:nth-child(1)': {
        gridRow: 1,
        gridColumn: 1
    },
    'div:nth-child(2)': {
        gridRow: 1,
        gridColumn: 2
    },
    'div:nth-child(3)': {
        gridRow: 2,
        gridColumn: 1
    },
    'div:nth-child(4)': {
        gridRow: 2,
        gridColumn: 2
    },
    'div:nth-child(5)': {
        gridRow: '1 / 3',
        gridColumn: 3
    },

    [theme.mediaQuery.largeMobileOnly]: {
        gridTemplateRows: 'repeat(3, minmax(0, 1fr))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',

        'div:nth-child(1)': {
            gridRow: 1,
            gridColumn: 1
        },
        'div:nth-child(2)': {
            gridRow: 1,
            gridColumn: 2
        },
        'div:nth-child(3)': {
            gridRow: 2,
            gridColumn: 1
        },
        'div:nth-child(4)': {
            gridRow: 3,
            gridColumn: 1
        },
        'div:nth-child(5)': {
            gridRow: '2 / -1',
            gridColumn: 2
        }
    }
}));

function Mosaic({ items }) {

    const mosaicImage = items.map((item, index) => {
        return (
            <Container key={index}>
                { item.type === 'video' ? (
                    <Container direction="column">
                        <Video muted autoPlay loop playsInline >
                            <source src={item.srcImg} type="video/mp4" />
                        </Video>
                        { item.caption ? <Caption>{item.caption}</Caption> : null }
                    </Container>
                ) : (
                    <Container direction="column">
                        <Image src={item.srcImg} alt={item.caption} />
                        { item.caption ? <Caption>{item.caption}</Caption> : null }
                    </Container>
                ) }
            </Container>
        );
    });

    return (
        <Container isFullWidth={false}>
            <MosaicContainer>
                {mosaicImage}
            </MosaicContainer>
        </Container>
    );
}

export default Mosaic;