import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import StyledButton from '@components/Button';
import { isReleased } from '@utils/globals';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import Countdown from '@components/Countdown';

const HeroContainer = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    backgroundColor: theme.colors.primary.contrastColor,
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column'
    }
}));

const MainImg = styled.img(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 1,
    // maxHeight: '450px',
    [ theme.mediaQuery.largeMobileOnly ]: {
        width: '100%',
        height: 'auto',
        maxHeight: '500px'
    }
}));

const Logo = styled.img(() => ({
    height: '100%',
    width: '45px',
    objectFit: 'contain'
}));

const Title = styled.h1(({ theme }) => ({
    ...theme.typography.headers,
    // textTransform: 'uppercase',
    lineHeight: '130%',
    fontWeight: 'bold',
    color: theme.colors.common.text
}));

const Subtitle = styled.div(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text
}));

const Description = styled.div(({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '180%',
    marginBottom: '1rem',
    color: theme.colors.common.text
}));

const Portrait = styled.img(({ theme }) => ({
    height: '500px',
    maxWidth: '500px',
    width: '100%',
    objectFit: 'contain'
}));

const OverlayButtonContainer = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    position: 'absolute',
    zIndex: 100,
    left: 0,
    width: '100%',
    [ theme.mediaQuery.mobileOnly]: {
        display: 'none'
    },
    [ theme.mediaQuery.largeMobileUp]: {
        display: 'none'
    },
    [ theme.mediaQuery.tabletUp]: {
        bottom: '15px',
        display: 'flex'
    },
    [ theme.mediaQuery.desktopUp]: {
        bottom: '50px',
        display: 'flex'
    }
}));

const OverlayLeft = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    width: '42%'
}));

const OverlayMiddle = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xs,
    width: '46%',
    textAlign: 'center',
    'button': {
        margin: '0 auto'
    }
}));

const OverlayRight = styled.div(({ theme }) => ({
    display: 'flex',
    width: '12%'
}));

const SecondImg = styled.img(({ theme }) => ({
    height: '180px',
    width: '180px',
    position: 'absolute',
    right: theme.spacing.xs,
    transform: 'translateX(8%)',
    top: '100%',
    display: 'none',
    [ theme.mediaQuery.tabletLandscapeUp ]: {
        display: 'block'
    }
}));

const ContentContainer = styled.div(({ theme, mainImage, portrait }) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: mainImage ? 'left' : 'center',
    maxWidth: '120ch',
    margin: '0 auto',
    padding: `${theme.spacing.l} ${theme.spacing.xs}`,
    gap: theme.spacing.m,
    alignItems: !mainImage || !portrait ? 'center' : '',
    [ theme.mediaQuery.largeMobileOnly ]: {
        alignItems: 'center'
    },
    [ theme.mediaQuery.tabletLandscapeUp ]: {
        flexDirection: 'row',
        padding: `${theme.spacing.xl} ${theme.spacing.l}`
    }
}));

const Video = styled.video(({ theme }) => ({
    width: '100%',
    maxWidth: '500px',
    objectFit: 'contain',
    [ theme.mediaQuery.largeMobileUp ]: {
        maxWidth: '50% !important'
    }
}));

const Link = styled.a(({ theme }) => ({
    textDecoration: 'none',
    width: '100%'
}));

function Hero({ mainImage, logo, title, subtitle, description, button, secondaryButton, portrait, buttonCallback, secondaryButtonCallback, overlayButton, video, linkUrl }) {
    const theme = useTheme();

    const contentStyle = {
        alignItems: 'left',
        'a': {
            color: theme.colors.primary.dark,
            '&:hover': {
                color: theme.colors.primary.light
            }
        },
        [ theme.mediaQuery.largeMobileOnly ]: {
            alignItems: 'center'
        }
    };

    const buttonContainer = {
        justifyContent: 'center',
        [ theme.mediaQuery.largeMobileUp ]: {
            justifyContent: 'flex-start !important'
        }
    };

    const countDownStyle = {
        color: theme.colors.primary.dark,
        paddingTop: theme.spacing.l
    };

    return (
        <Container direction="column" isFullWidth>
            <Link href={linkUrl.link} target="_blank" rel="noreferrer">
                <HeroContainer>
                    { mainImage ? <MainImg src={mainImage} alt="image" /> : null }
                    <OverlayButtonContainer>
                        <OverlayLeft />
                        <OverlayMiddle>
                            { overlayButton ? <StyledButton onClick={buttonCallback}>{ isReleased() ? STRINGS.buyPacks : STRINGS.formButtonPreRelease }</StyledButton> : null }
                        </OverlayMiddle>
                        <OverlayRight />
                    </OverlayButtonContainer>
                </HeroContainer>
            </Link>
            { !isReleased() ? (
                <Container style={countDownStyle}>
                    <Countdown />
                </Container>
            ) : null }
            { button || logo || title || subtitle || description || portrait ? (
                <Container direction="column">
                    <ContentContainer mainImage={mainImage}>
                        <Container direction="column" style={contentStyle} gap={theme.spacing.xs}>
                            { logo ? <Logo src={logo} /> : null }
                            { title ? <Title>{title}</Title> : null }
                            { subtitle ? <Subtitle>{subtitle}</Subtitle> : null }
                            { description ? <Description>{description}</Description> : null }
                            <Container style={buttonContainer}>
                                { button ? <StyledButton onClick={buttonCallback}>{ isReleased() ? STRINGS.buyPacks : STRINGS.formButtonPreRelease }</StyledButton> : null }
                            </Container>
                        </Container>
                        { video ? (
                            <Video autoPlay loop playsInline controls>
                                <source src={video} type="video/mp4" />
                            </Video>
                        ) : (
                            <Portrait src={portrait} />
                        ) }
                    </ContentContainer>
                </Container>
            ) : null }
        </Container>
    );
}

export default Hero;
